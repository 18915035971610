#features {
  /* padding: 6rem 0; */
}

@media screen and (max-width: 400px) {
  .intro-text {
    padding-top: 20rem !important;
  }
  .HeaderTitle {
    font-size: 5rem !important;
    text-align: start !important;
  }
  .HeaderDesc {
    font-size: 1.7rem !important;
    text-align: start !important;
  }
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
